

const routerArr = [
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home/index.vue'),
        meta: {
            title: '首页',
        }
    },
    {
        path: '/style',
        name: 'Style',
        component: () => import('../views/style/index.vue'),
        meta: {
            title: '装修风格',
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import('../views/product/index.vue'),
        meta: {
            title: '产品展示',
        }
    },
    
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/about/index.vue'),
        meta: {
            title: '关于我们',
        }
    },
]

export default routerArr