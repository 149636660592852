import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/rem.js';
import '@/assets/font/font.scss';
import MetaInfo from "vue-meta-info";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(MetaInfo).use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
